import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

export interface ReuploadTimesheetContentProps {
  onUploadClick: () => void;
  onCloseClick: () => void;
}

export function ReuploadTimesheetContent(props: ReuploadTimesheetContentProps) {
  const { onUploadClick, onCloseClick } = props;

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="document-review"
        title="Upload new timesheet?"
      />
      <DialogFooter orientation="vertical" sx={{ paddingTop: 11 }} onClose={onCloseClick}>
        <Button fullWidth variant="contained" size="large" onClick={onUploadClick}>
          Reupload
        </Button>
      </DialogFooter>
    </>
  );
}

import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

export interface TimesheetUploadedContentProps {
  onConfirmClick: () => void;
}

export function TimesheetUploadedContent(props: TimesheetUploadedContentProps) {
  const { onConfirmClick } = props;

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="document-review"
        title="We're reviewing your timesheet"
        description="You should hear from us within the next two business days."
      />
      <DialogFooter orientation="vertical" sx={{ paddingTop: 11 }}>
        <Button fullWidth variant="contained" size="large" onClick={onConfirmClick}>
          Got it
        </Button>
      </DialogFooter>
    </>
  );
}

import { Skeleton, Stack } from "@mui/material";

import { ShiftWithContentCardSkeleton } from "../../Shift/Card/CardWithContentSkeletonLoader";

function ContentSkeleton() {
  return (
    <Stack spacing={4}>
      <Skeleton variant="text" width="70%" height={30} />
      <Stack spacing={1}>
        <Skeleton variant="text" width="100%" height={15} />
        <Skeleton variant="text" width="85%" height={15} />
      </Stack>
      <Skeleton
        variant="rounded"
        width="100%"
        height={40}
        sx={(theme) => ({
          borderRadius: theme.shape.borderRadius,
        })}
      />
    </Stack>
  );
}

export function UnverifiedShiftsLoadingPageState() {
  return (
    <Stack spacing={4}>
      <ShiftWithContentCardSkeleton loadingLabel="Loading unverified shift">
        <ContentSkeleton />
      </ShiftWithContentCardSkeleton>
      <ShiftWithContentCardSkeleton>
        <ContentSkeleton />
      </ShiftWithContentCardSkeleton>
      <ShiftWithContentCardSkeleton>
        <ContentSkeleton />
      </ShiftWithContentCardSkeleton>
    </Stack>
  );
}

import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

import { type UnverifiedShiftsResponse, unverifiedShiftsResponseSchema } from "./types";

export const GET_WORKER_UNVERIFIED_SHIFTS_PATH = "/v1/shifts/getUnverifiedShifts";
export const WORKER_UNVERIFIED_SHIFTS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${GET_WORKER_UNVERIFIED_SHIFTS_PATH}`;

export function useWorkerUnverifiedShifts(
  options: UseGetQueryOptions<UnverifiedShiftsResponse> = {}
): UseQueryResult<UnverifiedShiftsResponse> {
  return useGetQuery({
    url: WORKER_UNVERIFIED_SHIFTS_URL,
    responseSchema: unverifiedShiftsResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_UNVERIFIED_SHIFTS_FAILURE,
    },
    ...options,
  });
}

import { BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Diagnostic } from "@ionic-native/diagnostic";
import { CameraPermissionDeniedError } from "@src/appV2/Accounts/DocumentDetails/errors";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";

export interface ErrorUploadingTimesheetContentProps {
  uploadError?: Error | CameraPermissionDeniedError;
  onTryAgainClick: () => void;
  onCancelClick: () => void;
}

export function ErrorUploadingTimesheetContent(props: ErrorUploadingTimesheetContentProps) {
  const { onTryAgainClick, onCancelClick, uploadError } = props;

  const isCameraPermissionDeniedError = uploadError instanceof CameraPermissionDeniedError;

  const title = isCameraPermissionDeniedError
    ? "Camera permission denied"
    : "Please try uploading again";
  const description = isCameraPermissionDeniedError
    ? "Please share camera access with Clipboard in device settings to continue."
    : "There was an issue with the upload of your document";

  return (
    <>
      <BottomSheetIllustrationContent
        illustrationType="document-rejected"
        title={title}
        description={description}
      />
      <DialogFooter
        orientation="vertical"
        closeButtonAriaLabel="Cancel timesheet upload"
        sx={{
          paddingTop: 11,
        }}
        onClose={onCancelClick}
      >
        {isCameraPermissionDeniedError ? (
          <Button
            fullWidth
            size="large"
            onClick={async () => {
              await Diagnostic.switchToSettings();
            }}
          >
            Go To Settings
          </Button>
        ) : (
          <Button
            fullWidth
            size="large"
            onClick={() => {
              onTryAgainClick();
            }}
          >
            Upload timesheet
          </Button>
        )}
      </DialogFooter>
    </>
  );
}

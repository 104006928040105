import { CameraSource } from "@capacitor/camera";
import { Stack } from "@mui/material";
import { type CameraPermissionDeniedError } from "@src/appV2/Accounts/DocumentDetails/errors";
import { useTakePhoto } from "@src/appV2/Accounts/DocumentDetails/hooks/useTakePhoto";
import { type UploadFileData } from "@src/appV2/Accounts/DocumentDetails/types";
import { Button } from "@src/appV2/redesign/components/Button";
import { IconButton } from "@src/appV2/redesign/components/IconButton";

export interface ChoosePhotoContentProps {
  onPhotoTaken: (photo: UploadFileData) => void;
  onError: (error: Error | CameraPermissionDeniedError) => void;
  onCloseClick: () => void;
}

export function ChoosePhotoContent(props: ChoosePhotoContentProps) {
  const { onPhotoTaken, onError, onCloseClick } = props;

  const takePhoto = useTakePhoto({
    source: CameraSource.Camera,
    onSuccess: (photo) => {
      onPhotoTaken(photo);
    },
    onError,
  });

  const chooseFromLibrary = useTakePhoto({
    source: CameraSource.Photos,
    onSuccess: (photo) => {
      onPhotoTaken(photo);
    },
    onError,
  });
  return (
    <Stack direction="column" spacing={4} padding={4} paddingBottom={6} justifyContent="center">
      <Button size="large" variant="outlined" onClick={takePhoto}>
        Take a photo
      </Button>
      <Button size="large" variant="outlined" onClick={chooseFromLibrary}>
        Upload from library
      </Button>

      <IconButton
        aria-label="Cancel timesheet upload"
        size="large"
        variant="outlined"
        iconType="close"
        sx={{ alignSelf: "center" }}
        onClick={onCloseClick}
      />
    </Stack>
  );
}

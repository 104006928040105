import { BottomSheet, BottomSheetLoadingContent } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type UploadFileData } from "@src/appV2/Accounts/DocumentDetails/types";
import { useEffect, useState } from "react";

import { ChoosePhotoContent } from "./ChosePhotoContent";
import { ErrorUploadingTimesheetContent } from "./ErrorUploadingTimesheetContent";
import { ReuploadTimesheetContent } from "./ReuploadTimesheetContent";
import { TimesheetUploadedContent } from "./TimesheetUploadedContent";
import { useUpdateShiftTimecard } from "./useUpdateShiftTimecard";
import { useUploadShiftTimecardToS3 } from "./useUploadShiftTimecardToS3";

export interface UploadTimesheetBottomSheetProps {
  modalState: UseModalState;
  shiftId?: string;
  isTimesheetUploaded: boolean;
  onUploadStarted: () => void;
  onSuccess: (params: { uploadDurationInMs: number; updateTimecardDurationInMs: number }) => void;
  onError: (error?: Error) => void;
}

type ContentType =
  | "choose-photo"
  | "reupload-timesheet"
  | "uploading-timesheet"
  | "timesheet-uploaded"
  | "error-uploading-timesheet";

const BOTTOM_SHEET_CLOSING_TRANSITION_IN_MS = 300;

export function UploadTimesheetBottomSheet(props: UploadTimesheetBottomSheetProps) {
  const { modalState, shiftId, isTimesheetUploaded, onUploadStarted, onSuccess, onError } = props;

  const startingContentType = isTimesheetUploaded ? "reupload-timesheet" : "choose-photo";
  const [contentType, setContentType] = useState<ContentType>(startingContentType);
  const [uploadError, setUploadError] = useState<Error>();

  const [uploadStartTime, setUploadStartTime] = useState(0);
  const [updateTimecardStartTime, setUpdateTimecardStartTime] = useState(0);

  const { mutateAsync: uploadShiftTimecardToS3 } = useUploadShiftTimecardToS3();
  const { mutateAsync: updateShiftTimecard } = useUpdateShiftTimecard();

  useEffect(() => {
    setContentType(startingContentType);
  }, [shiftId, startingContentType]);

  if (!isDefined(shiftId)) {
    return null;
  }

  function resetState() {
    const currentContentType = contentType;

    setTimeout(() => {
      setContentType(startingContentType);
      setUploadError(undefined);
    }, BOTTOM_SHEET_CLOSING_TRANSITION_IN_MS);

    if (currentContentType === "timesheet-uploaded") {
      onSuccess({
        uploadDurationInMs: Date.now() - uploadStartTime,
        updateTimecardDurationInMs: Date.now() - updateTimecardStartTime,
      });
    }

    if (currentContentType === "error-uploading-timesheet") {
      onError(uploadError);
    }
  }

  return (
    <BottomSheet
      modalState={modalState}
      onClose={() => {
        resetState();
      }}
    >
      {contentType === "choose-photo" && (
        <ChoosePhotoContent
          onPhotoTaken={async (photo: UploadFileData) => {
            onUploadStarted();
            setContentType("uploading-timesheet");

            try {
              setUploadStartTime(Date.now());
              const timecard = await uploadShiftTimecardToS3({
                shiftId,
                type: photo.contentType,
                fileBlob: photo.blob,
              });
              setUpdateTimecardStartTime(Date.now());
              await updateShiftTimecard({
                shiftId,
                timecard: [timecard],
              });
              setContentType("timesheet-uploaded");
            } catch (error) {
              const resolvedError = error instanceof Error ? error : new Error(String(error));
              setContentType("error-uploading-timesheet");
              setUploadError(resolvedError);
            }
          }}
          onError={(error) => {
            setContentType("error-uploading-timesheet");
            setUploadError(error);
          }}
          onCloseClick={() => {
            modalState.closeModal();
            resetState();
          }}
        />
      )}
      {contentType === "uploading-timesheet" && (
        <BottomSheetLoadingContent
          title={
            <>
              Uploading
              {/* eslint-disable-next-line no-restricted-syntax */}
              <br />
              Document
            </>
          }
          description="Should only take a few seconds"
        />
      )}
      {contentType === "timesheet-uploaded" && (
        <TimesheetUploadedContent
          onConfirmClick={() => {
            modalState.closeModal();
            resetState();
          }}
        />
      )}
      {contentType === "error-uploading-timesheet" && (
        <ErrorUploadingTimesheetContent
          uploadError={uploadError}
          onTryAgainClick={() => {
            setUploadError(undefined);
            setContentType("choose-photo");
          }}
          onCancelClick={() => {
            modalState.closeModal();
            resetState();
          }}
        />
      )}
      {contentType === "reupload-timesheet" && (
        <ReuploadTimesheetContent
          onUploadClick={() => {
            setContentType("choose-photo");
          }}
          onCloseClick={() => {
            modalState.closeModal();
            resetState();
          }}
        />
      )}
    </BottomSheet>
  );
}

import { Text, Title } from "@clipboard-health/ui-react";
import { Link, Stack } from "@mui/material";

import { Button } from "../../components/Button";

export interface UnverifiedShiftCardContentProps {
  isTimesheetUploaded: boolean;
  onUploadTimesheetClick: (type: "upload" | "reupload") => void;
}

export function UnverifiedShiftCardContent(props: UnverifiedShiftCardContentProps) {
  const { isTimesheetUploaded, onUploadTimesheetClick } = props;

  return (
    <Stack spacing={6}>
      <Stack spacing={3}>
        <Title component="h6">Upload a paper timesheet to get paid</Title>
        <Text variant="body2" color={(theme) => theme.palette.text.secondary}>
          In order to get paid for this shift, you will need to submit a paper timesheet.
          <Link
            target="_blank"
            href="https://support.clipboardhealth.com/hc/en-us/articles/4625185574423-How-do-I-submit-a-paper-timesheet"
            sx={{ marginLeft: 1 }}
          >
            Learn more.
          </Link>
        </Text>
      </Stack>
      <Button
        variant={isTimesheetUploaded ? "outlined" : "contained"}
        onClick={() => {
          onUploadTimesheetClick(isTimesheetUploaded ? "reupload" : "upload");
        }}
      >
        {isTimesheetUploaded ? "Reupload timesheet" : "Upload timesheet"}
      </Button>
    </Stack>
  );
}

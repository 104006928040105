import { useModalState } from "@clipboard-health/ui-react";
import { getTheme } from "@clipboard-health/ui-theme";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, ThemeProvider } from "@mui/material";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics/log";
import { PullToRefresh } from "@src/appV2/lib/PullToRefresh/PullToRefresh";
import { PageWrapper } from "@src/appV2/redesign/components/PageWrapper";
import { StickyScrollPageHeader } from "@src/appV2/redesign/components/StickyScrollPageHeader";
import { type Shift, shiftPeriodToTimeSlot } from "@src/appV2/Shifts/Shift/types";
import { useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { ShiftWithContentCard } from "../../Shift/Card/CardWithContent";
import { getFormattedShiftPayBreakdown } from "../../Shift/getFormattedShiftPayBreakdown";
import { getShiftWorkDurationInHours } from "../../utils/getShiftWorkDuration";
import { PageContent } from "../components/PageContent";
import { UnverifiedShiftCardContent } from "./CardContent";
import { UnverifiedShiftsLoadingPageState } from "./LoadingPageState";
import { UnverifiedShiftsEvents } from "./UnverifiedShiftsEvents";
import { UploadTimesheetBottomSheet } from "./UploadTimesheet/UploadTimesheetBottomSheet";
import {
  useWorkerUnverifiedShifts,
  WORKER_UNVERIFIED_SHIFTS_URL,
  // eslint-disable-next-line import/max-dependencies
} from "./useWorkerUnverifiedShifts";

export function UnverifiedShiftsPage() {
  const uploadTimesheetBottomSheetModalState = useModalState();
  const [uploadTimesheetShift, setUploadTimesheetShift] = useState<Shift>();

  const {
    data: unverifiedShiftsData,
    isLoading,
    refetch: refetchUnverifiedShifts,
  } = useWorkerUnverifiedShifts();
  const queryClient = useQueryClient();
  const unverifiedShifts = unverifiedShiftsData?.response ?? [];

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <ThemeProvider theme={getTheme()}>
      <PageWrapper>
        <StickyScrollPageHeader title="Unverified Shifts" scrollContainerRef={scrollRef} />

        <PageContent ref={scrollRef}>
          <PullToRefresh
            onRefresh={async () => {
              await refetchUnverifiedShifts();
            }}
          />
          {isLoading && <UnverifiedShiftsLoadingPageState />}
          {!isLoading && (
            <Stack spacing={4}>
              {unverifiedShifts.map((shift) => {
                const shiftPayBreakdown = getFormattedShiftPayBreakdown(
                  shift.offer?.pay.value,
                  shift.finalPay,
                  shift.originalAmount
                );

                const isTimesheetUploaded =
                  isDefined(shift.timecard?.files) && shift.timecard?.files.length > 0;

                return (
                  <ShiftWithContentCard
                    key={shift._id}
                    shiftTimeSlot={shiftPeriodToTimeSlot[shift.name]}
                    shiftStart={shift.start}
                    shiftEnd={shift.end}
                    shiftQualificationName={shift.agentReq}
                    shiftHourlyPay={shiftPayBreakdown?.hourlyPay}
                    shiftTotalPay={shiftPayBreakdown?.totalPay}
                    shiftWorkDurationInHours={getShiftWorkDurationInHours(
                      shift,
                      shift.facility.requiresLunchBreak ?? false
                    )}
                    workplaceName={shift.facility.name}
                    workplaceTimezone={shift.facility.tmz ?? ""}
                  >
                    <UnverifiedShiftCardContent
                      isTimesheetUploaded={isTimesheetUploaded}
                      onUploadTimesheetClick={() => {
                        setUploadTimesheetShift(shift);
                        uploadTimesheetBottomSheetModalState.openModal();
                      }}
                    />
                  </ShiftWithContentCard>
                );
              })}
            </Stack>
          )}
          <UploadTimesheetBottomSheet
            modalState={uploadTimesheetBottomSheetModalState}
            shiftId={uploadTimesheetShift?._id}
            isTimesheetUploaded={(uploadTimesheetShift?.timecard?.files?.length ?? 0) > 0}
            onUploadStarted={() => {
              logEvent(APP_V2_APP_EVENTS.UNVERIFIED_SHIFT_ACTION, {
                type: UnverifiedShiftsEvents.TIMESHEET_UPLOAD_STARTED,
                shiftId: uploadTimesheetShift?._id,
                workerId: uploadTimesheetShift?.agentId,
                workplaceId: uploadTimesheetShift?.facilityId,
              });
            }}
            onSuccess={async ({ uploadDurationInMs, updateTimecardDurationInMs }) => {
              const shift = uploadTimesheetShift;
              const isTimesheetUploaded = (shift?.timecard?.files?.length ?? 0) > 0;
              setUploadTimesheetShift(undefined);
              await queryClient.invalidateQueries({ queryKey: [WORKER_UNVERIFIED_SHIFTS_URL] });

              if (isDefined(shift)) {
                logEvent(APP_V2_APP_EVENTS.UNVERIFIED_SHIFT_ACTION, {
                  type: UnverifiedShiftsEvents.TIMESHEET_UPLOADED,
                  shiftId: shift._id,
                  workerId: shift.agentId,
                  workplaceId: shift.facilityId,
                  isReupload: isTimesheetUploaded,
                  uploadDurationInMs,
                  updateTimecardDurationInMs,
                });
              }
            }}
            onError={(error) => {
              if (isDefined(uploadTimesheetShift)) {
                logEvent(APP_V2_APP_EVENTS.UNVERIFIED_SHIFT_ACTION, {
                  type: UnverifiedShiftsEvents.TIMESHEET_UPLOAD_ERROR,
                  shiftId: uploadTimesheetShift._id,
                  workerId: uploadTimesheetShift.agentId,
                  workplaceId: uploadTimesheetShift.facilityId,
                  error: error?.message ?? "Unknown error",
                });
              }

              setUploadTimesheetShift(undefined);
            }}
          />
        </PageContent>
      </PageWrapper>
    </ThemeProvider>
  );
}

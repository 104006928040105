import { shiftSchema, timecardSchema } from "@src/appV2/Shifts/Shift/types";
import { z } from "zod";

const unverifiedShiftSchema = shiftSchema.extend({
  businessrules: z
    .object({
      key: z.string(),
      value: z.string(),
      description: z.string().optional(),
    })
    .optional(),
});
export type UnverifiedShift = z.infer<typeof unverifiedShiftSchema>;

export const unverifiedShiftsResponseSchema = z.object({
  success: z.boolean(),
  response: z.array(unverifiedShiftSchema),
});
export type UnverifiedShiftsResponse = z.infer<typeof unverifiedShiftsResponseSchema>;

export const getPresignedUrlResponseSchema = z.object({
  presigned_url: z.string(),
});
export type GetPresignedUrlResponse = z.infer<typeof getPresignedUrlResponseSchema>;

export const uploadShiftTimecardToS3RequestSchema = z.object({
  shiftId: z.string(),
  type: z.string(),
  fileBlob: z.instanceof(Blob),
});
export type UploadShiftTimecardToS3Params = z.infer<typeof uploadShiftTimecardToS3RequestSchema>;
export const uploadShiftTimecardToS3ResponseSchema = z.object({
  url: z.string(),
  filename: z.string(),
  fileStorageFileKey: z.string(),
});
export type UploadShiftTimecardToS3Response = z.infer<typeof uploadShiftTimecardToS3ResponseSchema>;

export const updateShiftTimecardRequestSchema = z.object({
  timecard: z.array(uploadShiftTimecardToS3ResponseSchema),
  shiftId: z.string(),
  location: z.array(z.string()).optional(),
});
export type UpdateShiftTimecardParams = z.infer<typeof updateShiftTimecardRequestSchema>;

export const updateShiftTimecardResponseSchema = z.object({
  success: z.boolean(),
  response: z.object({
    timecard: timecardSchema,
  }),
  error: z.string().nullable(),
});
export type UpdateShiftTimecardResponse = z.infer<typeof updateShiftTimecardResponseSchema>;

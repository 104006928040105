import { put } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { GET_WORKER_UNVERIFIED_SHIFTS_PATH } from "@src/appV2/redesign/WorkerShifts/UnverifiedShifts/useWorkerUnverifiedShifts";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import {
  type UpdateShiftTimecardParams,
  updateShiftTimecardRequestSchema,
  type UpdateShiftTimecardResponse,
  updateShiftTimecardResponseSchema,
} from "../types";

// FIXME Check why we even need to provide a timesheet location if it's always the same value (mimicking V1 API)
export const DEFAULT_TIMESHEET_LOCATION = {
  LONGITUDE: "12",
  LATITUDE: "34",
};

export function useUpdateShiftTimecard(
  options: UseMutationOptions<
    UpdateShiftTimecardResponse,
    AxiosError,
    UpdateShiftTimecardParams
  > = {}
): UseMutationResult<UpdateShiftTimecardResponse, AxiosError, UpdateShiftTimecardParams> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      const url = `${environmentConfig.REACT_APP_BASE_API_URL}/v1/shifts/timecard/${params.shiftId}`;

      const timecard = await put({
        url,
        data: {
          ...params,
          location: [DEFAULT_TIMESHEET_LOCATION.LONGITUDE, DEFAULT_TIMESHEET_LOCATION.LATITUDE],
        },
        requestSchema: updateShiftTimecardRequestSchema,
        responseSchema: updateShiftTimecardResponseSchema,
      });

      return timecard.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.UPDATE_SHIFT_TIMECARD_FAILURE,
    },
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries({
        queryKey: [
          `${environmentConfig.REACT_APP_BASE_API_URL}${GET_WORKER_UNVERIFIED_SHIFTS_PATH}`,
        ],
      });
      return options.onSuccess?.(data, variables, context);
    },
  });
}
